import React from 'react';

import { useAppSelector } from '@/on_demand/app/hooks';
import { tourSelector } from '@/on_demand/store/slices/tourSlice';
import styles from './styles.module.scss';

const DriverOldPosition: React.FC = () => {
  const { driverPosition } = useAppSelector(tourSelector);

  const getTimeDifferenceInMinutes = (): number | null => {
    if (!driverPosition || !driverPosition.ts) return null;

    const now = Date.now();
    const positionTimestamp = new Date(driverPosition.ts).getTime();
    const timeDifference = now - positionTimestamp;
    return Math.floor(timeDifference / (60 * 1000));
  };
  const timeDifference = getTimeDifferenceInMinutes();

  if (!driverPosition || timeDifference === null || timeDifference <= 5) {
    return null;
  }

  return <div className={styles.notification}>Последняя локация водителя — {timeDifference} минут назад</div>;
};

export default DriverOldPosition;
