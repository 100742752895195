import { useAppDispatch } from '@/on_demand/app/hooks';
import { getDriverPositionInfoAsync } from '@/on_demand/store/slices/tourSlice';
import { showToasterWithDelay } from '@/store/slices/toasterSlice';
import React, { useCallback, useEffect, useRef } from 'react';

// Function to generate a random delay
const getRandomDelay = () => {
  const start = 5000;
  const end = 10000;
  const step = 1000;

  // Possible delays in milliseconds [5000, 6000, 7000, 8000, 9000, 10000]
  const delays = Array.from({ length: Math.floor((end - start) / step) + 1 }, (_, i) => start + i * step);
  return delays[Math.floor(Math.random() * delays.length)];
};

const DriverPositionUpdater: React.FC<{ tourId: number }> = ({ tourId }) => {
  const dispatch = useAppDispatch();
  const timerRef = useRef<NodeJS.Timeout | null>(null); // Ref for timer storage

  // Main function to update the driver's position
  const updateDriverPosition = useCallback(async () => {
    try {
      // Send the request
      const result = await dispatch(getDriverPositionInfoAsync({ tour_id: tourId })).unwrap();

      // If we get the result and the component is still mounted
      if (result) {
        timerRef.current = setTimeout(updateDriverPosition, getRandomDelay()); // Recursive calling with delay
      }
    } catch (error) {
      // If the error, then stop pulling.
      console.error('Error fetching driver position:', error);
      dispatch(
        showToasterWithDelay({
          isVisible: true,
          type: 'error',
          message: 'Отслеживание водителя не доступно. Попробуйте обновить страницу.',
        })
      );
    }
  }, [dispatch, tourId]);

  useEffect(() => {
    // Start the cycle.
    timerRef.current = setTimeout(updateDriverPosition, 0);

    // Cleanup function to clear the timer on unmount
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current); // Clear the timer
      }
    };
  }, [updateDriverPosition]);
  return null;
};

export default DriverPositionUpdater;
