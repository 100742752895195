import L from 'leaflet';
import { Marker } from 'react-leaflet';

import { BusStopType } from '@/projects/on_demand/@types/tour';
import { getNearestRBS } from '@/projects/on_demand/utils';
import { forwardRef } from 'react';
import styles from './styles.module.scss';

const getPin = (kind: 'start' | 'finish', time: string) => {
  return L.divIcon({
    className: styles.customMarker,
    html: `
      <div class="${styles.customMarker}">
        <div class="${styles.innerBox}">
          <span class="${styles.timeText}">
            ${time}
          </span>
        </div>
        <div class="${styles.stick}">
          <div class="${styles.stickShadow}"></div>
        </div>
        <div class="${styles.markerLabel} ${kind === 'start' ? styles.markerLabelStart : styles.markerLabelFinish}">
          <div class="${kind === 'start' ? styles.labelContent : styles.labelContentFinish}">
            <span>Точка ${kind === 'start' ? 'А' : 'Б'}</span>
          </div>
        </div>
      </div>
    `,
    iconSize: [54, 50],
    iconAnchor: [27, 60],
  });
};

interface MarkerProps {
  bs: BusStopType;
  kind: 'start' | 'finish';
  busStops: BusStopType[];
  onUpdatePosition: (newPosition: BusStopType, newIndex: number) => void;
}

export const DragMarker = forwardRef<L.Marker, MarkerProps>(({ bs, kind, busStops, onUpdatePosition }, ref) => {
  const handleDragEnd = (event: L.LeafletEvent) => {
    const newLatLng = event.target.getLatLng();
    const nearestRBS = getNearestRBS(newLatLng, busStops);
    onUpdatePosition(nearestRBS, busStops.indexOf(nearestRBS));
  };

  return (
    <Marker
      ref={ref}
      position={bs.location}
      icon={getPin(kind, bs.arrival_time)}
      draggable={true}
      eventHandlers={{
        dragend: handleDragEnd,
      }}
    />
  );
});
