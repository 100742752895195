import classNames from 'classnames';
import styles from './styles.module.scss';

interface RouteModalProps {
  onClose: () => void;
}

export const RouteModal: React.FC<RouteModalProps> = ({ onClose }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.main}>
        <div className={styles.text}>сегодняшний маршрут уже завершен. забронируешь место на завтра?</div>
        <div className={styles.btns}>
          <button className={classNames(styles.btn, styles.btnRed)} onClick={onClose}>
            оки
          </button>
          <button className={classNames(styles.btn, styles.btnGrey)} onClick={onClose}>
            не-а
          </button>
        </div>
      </div>
      <div className={styles.close} onClick={onClose}>
        &times;
      </div>
    </div>
  );
};
