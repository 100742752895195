import gpsIconSrc from '@/on_demand/assets/gps_icon.png';
import React from 'react';
import { useMap } from 'react-leaflet';
import FloatingButton from '../FloatingButton';

const GpsButton: React.FC = () => {
  const map = useMap();

  const handleGpsButtonClick = () => {
    map
      .locate({
        setView: true,
        maxZoom: 15,
        enableHighAccuracy: true,
      })
      .on('locationfound', (e: L.LocationEvent) => {
        console.log('User location found:', e.latlng);
        map.setView(e.latlng, 15, { animate: true });
      })
      .on('locationerror', (e: L.ErrorEvent) => {
        console.error('Error locating user:', e.message);
      });
  };

  return (
    <FloatingButton position={{ bottom: '235px', right: '10px' }} onClick={handleGpsButtonClick}>
      <img src={gpsIconSrc} alt="gps" style={{ width: '26px' }} />
    </FloatingButton>
  );
};

export default GpsButton;
