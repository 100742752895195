import { useIsMobileFromSearchParams, usePhoneFromSearchParams } from '@/hooks/useSearchParams';
import { NotFoundPage } from '@/pages/shuttle/NotFoundPage';
import { useAppSelector } from '@/projects/on_demand/app/hooks';
import { mainSelector } from '@/store/slices/mainSlice';
import { getRandomYandexAPIKey } from '@/utils/yandex';
import { FC, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { TourPage } from './pages/TourPage';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ymaps from 'ymaps';
import styles from './styles.module.scss';

const App: FC = () => {
  const { isMobile } = useAppSelector(mainSelector);
  const memorizedYandexAPIKey = useMemo(() => getRandomYandexAPIKey(), []);
  ymaps.load(`https://api-maps.yandex.ru/2.1/?apikey=${memorizedYandexAPIKey}&lang=ru_RU`);

  usePhoneFromSearchParams();
  useIsMobileFromSearchParams();

  return (
    <div className={styles.main}>
      {isMobile && (
        <div
          className="close-btn"
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (window.closeHandler) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              window.closeHandler.postMessage('');
            }
          }}
        >
          &times;
        </div>
      )}
      <Routes>
        <Route index element={<TourPage />} key="Index" />
        <Route path="*" element={<NotFoundPage />} key="DefaultPage" />
      </Routes>
    </div>
  );
};

export default App;
