import { AppDispatch, RootState } from '@/store/store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface ToasterState {
  isVisible: boolean;
  type: 'error' | 'warning' | 'success';
  message: string;
}

const initialState: ToasterState = {
  isVisible: false,
  type: 'success',
  message: '',
};

type ToasterPayload = {
  isVisible: boolean;
  type: 'error' | 'warning' | 'success';
  message: string;
};

export const toasterSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setToaster: (state, action: PayloadAction<ToasterPayload>) => {
      state.isVisible = true;
      state.type = action.payload.type;
      state.message = action.payload.message;
    },
    resetToaster: (state) => {
      state.isVisible = false;
      state.type = 'success';
      state.message = '';
    },
  },
});

export const showToasterWithDelay = (payload: ToasterPayload) => (dispatch: AppDispatch) => {
  dispatch(setToaster(payload));

  setTimeout(() => {
    dispatch(resetToaster());
  }, 3000);
};

export const { setToaster, resetToaster } = toasterSlice.actions;

export const toasterSelector = (state: RootState) => state.toasterReducer;

export default toasterSlice.reducer;
