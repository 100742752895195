import L from 'leaflet';
import React from 'react';
import { Marker } from 'react-leaflet';
import dropIcon from '@/on_demand/assets/bus_drop.svg';
import busIcon from '@/on_demand/assets/bus_icon.svg';

interface BusMarkerProps {
  position: [number, number];
  rotation: number;
}

export const BusMarker: React.FC<BusMarkerProps> = ({ position, rotation }) => {
  const createBusMarker = () => {
    const iconHtml = `
      <div style="position: relative; width: 35px; height: 50px; display: flex; justify-content: center; align-items: center;">
        <div style="position: absolute; top: 0; left: 0; width: 35px; height: 50px; transform: rotate(${rotation}deg); overflow: hidden;">
          <img src="${dropIcon}" style="width: 100%; height: 100%; object-fit: contain;" />
        </div>
        
        <img src="${busIcon}" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 16px; height: 16px;" />
      </div>
    `;

    return L.divIcon({
      className: 'bus-marker',
      html: iconHtml,
      iconSize: [35, 50],
      iconAnchor: [17.5, 25],
    });
  };

  return <Marker position={position} icon={createBusMarker()}></Marker>;
};
