import { DateType } from '@/@types/shuttle/date';
import { Btn, PassengerCount, PreLoader, TitleBlock } from '@/components';
import Select, { ValueType } from '@/components/shared/atoms/Select';
import Driver from '@/components/shuttle/molecules/Driver';
import PhoneForm from '@/components/shuttle/molecules/PhoneForm';
import { FakePhoneNumber } from '@/constants';
import { mainSelector } from '@/store/slices/mainSlice';
import { setToaster } from '@/store/slices/toasterSlice';
import { useAppDispatch } from '@/travel_shuttle/app/hooks';
import borovoe from '@/travel_shuttle/assets/img/borovoe.png';
import { buySubscriptionAsync, getTourInfoAsync, paymentSelector } from '@/travel_shuttle/store/slices/paymentSlice';
import { setIsTitleHidden, shuttleSelector } from '@/travel_shuttle/store/slices/shuttleSlice';
import { isValidNumber, parseNumber } from '@/utils/phone';
import classNames from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from './index.module.scss';

const routeBackData = [
  {
    label: 'Меня не интересует обратный путь',
    value: false,
  },
  {
    label: 'Меня интересует обратный путь',
    value: true,
  },
];

const PaymentController: FC = () => {
  const dispatch = useAppDispatch();
  const { isTitleHidden } = useSelector(shuttleSelector);
  const { isMobile, phone } = useSelector(mainSelector);
  const {
    isLoading,
    driver,
    subscriptionKind,
    oneTripPrice,
    dayOnlineTrialSubscriptionPrice,
    weeklyTrialSubscriptionPrice,
    upcomingTourDates,
  } = useSelector(paymentSelector);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tour_id = searchParams.get('tour_id');
  const pickup_stop_id = searchParams.get('pickup_stop_id');
  const delivery_stop_id = searchParams.get('dropoff_stop_id');
  const is_title_hidden = searchParams.get('is_title_hidden');
  const error = searchParams.get('error');
  const phoneFromSearchParams = searchParams.get('phone');
  const [selectedRouteBackData, setSelectedRouteBackData] = useState<ValueType>(routeBackData[0]);

  const [passengerCount, setPassengerCount] = useState<number>(1);
  const [selectedDate, setSelectedDate] = useState<DateType | null>(null);

  const fullPhone = useMemo(() => {
    if (phoneFromSearchParams) {
      return phoneFromSearchParams.startsWith('+') ? phoneFromSearchParams : `+${phoneFromSearchParams}`;
    }

    const phoneFromLocalStorage = localStorage.getItem('phone');
    if (!phone && phoneFromLocalStorage) {
      return phoneFromLocalStorage;
    }

    return phone;
  }, [phone, phoneFromSearchParams]);

  useEffect(() => {
    if (error) {
      dispatch(
        setToaster({
          isVisible: true,
          type: 'error',
          message: 'Произошла ошибка при оплате',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (!tour_id) {
      dispatch(
        setToaster({
          isVisible: true,
          type: 'warning',
          message: 'Не указана поездка',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tour_id]);

  useEffect(() => {
    if (is_title_hidden) {
      const value = is_title_hidden === 'true';
      dispatch(setIsTitleHidden(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_title_hidden]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (fullPhone && fullPhone !== FakePhoneNumber) {
      params.append('phone', encodeURIComponent(fullPhone));
    }

    if (tour_id) {
      dispatch(
        getTourInfoAsync({
          tourId: tour_id,
          paramsString: params.toString(),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tour_id, fullPhone]);

  useEffect(() => {
    if (upcomingTourDates) {
      setSelectedDate(upcomingTourDates[0]);
    }
  }, [upcomingTourDates]);

  const handleSubmit = () => {
    if (!tour_id) return;
    // Adds start_date only if selectedDateValue is not null.
    const data = Object.assign(
      {
        tour_id: Number(tour_id),
        phone: parseNumber(fullPhone),
        passenger_count: passengerCount,
        kind: subscriptionKind,
        pickup_point_id: Number(pickup_stop_id) || localStorage.getItem('pickup_point_id'),
        delivery_point_id: Number(delivery_stop_id) || localStorage.getItem('delivery_point_id'),
        is_mobile: isMobile,
        domain: 'travel.uvu.kz',
        additional_data: {
          wants_back_route: selectedRouteBackData!.value,
        },
      },
      selectedDate === null
        ? null
        : {
            start_date: selectedDate.value.toISOString().split('T')[0],
            // eslint-disable-next-line no-mixed-spaces-and-tabs
          }
    );

    dispatch(buySubscriptionAsync(data));
  };

  return (
    <>
      {isLoading ? (
        <PreLoader showText />
      ) : (
        <div className={styles.form}>
          {!isTitleHidden ? (
            <TitleBlock navigate={navigate} isMobile={isMobile} withBackBtn={true} withCloseBtn={false}>
              Бронирование
            </TitleBlock>
          ) : null}
          {driver ? (
            <Driver
              route_name={driver.route_name}
              photo_url={driver.photo_url}
              name={driver.name}
              car_name={driver.car_name}
              car_number_plate={driver.car_number_plate}
            />
          ) : null}
          {!isValidNumber(phone) ? <PhoneForm /> : null}
          <div className={styles.passengerCountBlock}>
            <PassengerCount passengerCount={passengerCount} updateCount={(value) => setPassengerCount(value)} />
          </div>

          <div className={styles.subscriptionKind}>
            <div className={styles.subscriptionKindFirstRow}>
              <input
                type="radio"
                name="kind"
                id="subscription-kind-daily"
                className={styles.subscriptionKindInput}
                value="day"
                checked={true}
                onChange={() => {}}
              />
              <label
                htmlFor="subscription-kind-daily"
                className={classNames(styles.subscriptionKindItem, styles.subscriptionKindItemDaily)}
              >
                <div className={styles.subscriptionKindInfo}>
                  <div className={styles.subscriptionKindAmount}>Комфорт</div>
                  <div className={styles.subscriptionKindDescription}>{oneTripPrice! * passengerCount} тг</div>
                  <div className={styles.subscriptionKindImage}>
                    <img src={borovoe} alt="borovoe" />
                  </div>
                </div>
              </label>
            </div>

            <div className={styles.select}>
              <Select
                options={routeBackData}
                onChange={(value) => {
                  if (value) {
                    setSelectedRouteBackData(value);
                  }
                }}
                inputClassName={styles.selectInput}
                menuClassName={styles.selectCustom}
                value={selectedRouteBackData}
              />
            </div>
          </div>

          <div>
            <Btn
              color="red"
              disabled={
                !isValidNumber(phone) || dayOnlineTrialSubscriptionPrice === 0 || weeklyTrialSubscriptionPrice === 0
              }
              onClick={handleSubmit}
              customClassName={styles.btn}
              isFixedBottom={true}
            >
              Забронировать
            </Btn>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentController;
