import MainLayout from '@/components/shared/layouts/MainLayout';
import React, { FC, useMemo } from 'react';

import { appNames } from './constants';
import FakeShuttleApp from './projects/fake_shuttle/App';
import OnDemandShuttleApp from './projects/on_demand/App';
import SchoolShuttleApp from './projects/school/App';
import ShuttleApp from './projects/shuttle/App';
import TravelShuttleApp from './projects/travel_shuttle/App';

const App: FC = (): React.ReactElement => {
  const app_name = import.meta.env.VITE_APP_NAME;

  const CurrentApp = useMemo(() => {
    switch (app_name) {
      case appNames.FAKE:
        return FakeShuttleApp;
      case appNames.SCHOOL:
        return SchoolShuttleApp;
      case appNames.TRAVEL:
        return TravelShuttleApp;
      case appNames.ONDEMAND:
        return OnDemandShuttleApp;
      default:
        return ShuttleApp;
    }
  }, [app_name]);

  return (
    <MainLayout>
      <CurrentApp />
    </MainLayout>
  );
};

export default App;
