import { LatLngTuple } from 'leaflet';

interface City {
  id: number;
  center: LatLngTuple;
}

interface CityRecord {
  [key: string]: City;
}

const appNames = {
  FAKE: 'fake_shuttle',
  SCHOOL: 'school',
  SHUTTLE: 'shuttle',
  TRAVEL: 'travel_shuttle',
  ONDEMAND: 'on_demand',
};
const yandexAPIKeys = [
  '9e562617-554b-4a92-a943-80621ca03ed5',
  '6e5cbf8f-d56b-47b4-a10e-83164d23e292',
  '24c60040-0371-411f-8fed-28520ca1d509',
];
const cities: Readonly<CityRecord> = {
  astana: {
    id: 1,
    center: [51.128207, 71.43042],
  },
  almaty: {
    id: 2,
    center: [43.237163, 76.945627],
  },
};
export const FakePhoneNumber = '+77770000000';
export const FakeCustomerId = 2218;
// eslint-disable-next-line react-refresh/only-export-components
export { appNames, cities, yandexAPIKeys };
