import React from 'react';
import styles from './FloatingButton.module.scss';

type FloatingButtonProps = {
  position?: { bottom?: string; top?: string; left?: string; right?: string };
  onClick?: () => void;
  children: React.ReactNode;
};

const FloatingButton: React.FC<FloatingButtonProps> = ({ position, onClick, children }) => {
  return (
    <div className={styles.floatingButton} style={position} onClick={onClick}>
      {children}
    </div>
  );
};

export default FloatingButton;
