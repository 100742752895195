import { DriverPositionType } from '@/projects/on_demand/@types/driver';
import { BusStopType, TourType } from '@/projects/on_demand/@types/tour';
import { AppDispatch, RootState } from '@/projects/on_demand/store/store';
import { showToasterWithDelay } from '@/store/slices/toasterSlice';
import { axiosInstance } from '@/utils/AxiosInstance';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

type tourData = { id: number };
type tourInfoResponse = TourType;

export const getTourInfoAsync = createAsyncThunk(
  'tour/getTourInfo',
  async (data: tourData): Promise<tourInfoResponse> => {
    return await axiosInstance.get<tourInfoResponse>(`/on-demand/closest-tour/${data.id}`).then((data) => data);
  }
);

type driverData = { tour_id: number };
type driverInfoResponse = DriverPositionType;

export const getDriverPositionInfoAsync = createAsyncThunk(
  'driver/getDriverPositionInfo',
  async (data: driverData): Promise<driverInfoResponse> => {
    return await axiosInstance
      .get<driverInfoResponse>(`/on-demand/last-tour-position/${data.tour_id}`)
      .then((data) => data);
  }
);

interface PayForm {
  tour_id: number;
  phone: string;
  pickup_rbs_id: number;
  dropoff_rbs_id: number;
  domain?: string;
  is_mobile?: string;
}

interface FieldError {
  field: string;
  message: string;
}
interface ValidationError {
  code: number;
  errors: FieldError[];
  message: string;
  status_code: number;
}

interface PayResponse {
  redirect_url: string;
}

export const payAsync = createAsyncThunk<
  PayResponse,
  PayForm,
  {
    dispatch: AppDispatch;
  }
>('pay/payAsync', async (data: PayForm, { dispatch }) => {
  try {
    return await axiosInstance.post<PayResponse>('/on-demand/pay', data);
  } catch (err) {
    const error = err as AxiosError;
    let message = 'Произошла ошибка при оплате.';
    const data = error.response?.data as ValidationError;
    if (data && data.errors) {
      const errors = data.errors.map((e) => e.message);
      message += '\n' + errors.join('\r\n');
    }
    dispatch(
      showToasterWithDelay({
        type: 'warning',
        message: message,
        isVisible: true,
      })
    );
    throw err;
  }
});
interface TourState {
  tour: TourType | null;
  driverPosition: DriverPositionType | null;
  pickupRBS: BusStopType | null;
  pickupIndex: number | null;
  dropOffRBS: BusStopType | null;
  dropoffIndex: number | null;
  isLoading: boolean;
  isSendingData: boolean;
}

const initialState: TourState = {
  tour: null,
  driverPosition: null,
  pickupRBS: null,
  pickupIndex: null,
  dropOffRBS: null,
  dropoffIndex: null,
  isLoading: true,
  isSendingData: false,
};

export const tourSlice = createSlice({
  name: 'tours',
  initialState,
  reducers: {
    setPickupRBS: (state, action) => {
      state.pickupRBS = action.payload;
    },
    setPickupIndex: (state, action) => {
      state.pickupIndex = action.payload;
    },
    setDropOffRBS: (state, action) => {
      state.dropOffRBS = action.payload;
    },
    setDropOffIndex: (state, action) => {
      state.dropoffIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTourInfoAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTourInfoAsync.fulfilled, (state, action) => {
      const response = action.payload as tourInfoResponse;
      state.tour = response;
      state.isLoading = false;
    });
    builder.addCase(getTourInfoAsync.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getDriverPositionInfoAsync.fulfilled, (state, action) => {
      const response = action.payload as DriverPositionType;
      state.driverPosition = response;
    });
    builder.addCase(getDriverPositionInfoAsync.rejected, () => {});
    builder.addCase(payAsync.pending, (state) => {
      state.isSendingData = true;
    });
    builder.addCase(payAsync.fulfilled, (state, action: PayloadAction<PayResponse>) => {
      state.isSendingData = false;
      window.location.href = action.payload.redirect_url;
    });
    builder.addCase(payAsync.rejected, (state) => {
      state.isSendingData = false;
    });
  },
});

export const { setPickupRBS, setDropOffRBS, setPickupIndex, setDropOffIndex } = tourSlice.actions;

export const tourSelector = (state: RootState) => state.tourReducer;

export default tourSlice.reducer;
