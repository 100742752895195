import busIconSrc from '@/on_demand/assets/bus_icon_dark.svg';
import React from 'react';
import { useMap } from 'react-leaflet';
import FloatingButton from '../FloatingButton';

type DriverPositionButtonProps = {
  driverPosition: { lat: number; lng: number };
};

const DriverPositionButton: React.FC<DriverPositionButtonProps> = ({ driverPosition }) => {
  const map = useMap();

  const handleDriverPositionClick = () => {
    if (driverPosition) {
      map.setView([driverPosition.lat, driverPosition.lng], 15, { animate: true });
      console.log('Moved to driver position:', driverPosition);
    } else {
      console.error('Driver position is not available');
    }
  };

  return (
    <FloatingButton position={{ bottom: '305px', right: '10px' }} onClick={handleDriverPositionClick}>
      <img src={busIconSrc} alt="bus" style={{ width: '24px' }} />
    </FloatingButton>
  );
};

export default DriverPositionButton;
