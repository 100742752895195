import { subscriptionKind } from '@/shuttle/constants';

export const purchaseAmount = (
  subscriptionKindValue: string,
  passengerCountValue: number,
  onlineOneTripPaymentAmountValue: number,
  subscriptionPaymentAmountValue: number
) => {
  switch (subscriptionKindValue) {
    case subscriptionKind.DAY:
      return passengerCountValue * onlineOneTripPaymentAmountValue;
    case subscriptionKind.MONTH_SUB:
      return passengerCountValue * subscriptionPaymentAmountValue;
    default:
      return 0;
  }
};
