import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

import kaspiSrc from './assets/kaspi.jpg';

type BtnProps = {
  customClassName?: string;
  onClick?: () => void;
  disabled?: boolean;
  isFixedBottom?: boolean;
};

const KaspiBtn: React.FC<BtnProps> = ({ customClassName, onClick, disabled, isFixedBottom }) => {
  return (
    <button
      className={classNames(styles.btn, isFixedBottom ? styles.btnFixedBottom : '', customClassName)}
      onClick={onClick}
      disabled={disabled}
    >
      Оплатить с{' '}
      <span className={styles.icon}>
        <img src={kaspiSrc} alt="kaspi" className={styles.img} />
      </span>
    </button>
  );
};

export default KaspiBtn;
