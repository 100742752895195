import styles from './styles.module.scss';

import { TourType } from '@/on_demand/@types/tour';
import dropIcon from '@/on_demand/assets/bus_drop.svg';
import busIcon from '@/on_demand/assets/bus_icon.svg';

interface TrackingModalProps {
  tour: TourType;
  onClose: () => void;
}

export const TrackingModal: React.FC<TrackingModalProps> = ({ tour, onClose }) => {
  const firstBusStop = tour.bus_stops.length > 0 ? tour.bus_stops[0] : null;
  return firstBusStop ? (
    <div className={styles.wrapper}>
      <div className={styles.main}>
        <div className={styles.icon}>
          <div className={styles.iconDrop}>
            <img src={dropIcon} alt="drop" />
          </div>
          <div className={styles.iconBus}>
            <img src={busIcon} alt="bus" />
          </div>
        </div>
        <div className={styles.text}>
          отслеживание шаттла станет доступно когда шаттл начнет свой маршрут: в {firstBusStop.arrival_time}
        </div>
      </div>
      <div className={styles.close} onClick={onClose}>
        &times;
      </div>
    </div>
  ) : null;
};
