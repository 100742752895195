import mainReducer from '@/store/slices/mainSlice';
import toasterReducer from '@/store/slices/toasterSlice';
import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import tourReducer from './slices/tourSlice';

export const store = configureStore({
  reducer: {
    mainReducer,
    toasterReducer,
    tourReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: import.meta.env.VITE_APP_ACTIVE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
