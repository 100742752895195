import logo from '@/assets/shuttle/img/logo.png';

import { useAppSelector } from '@/projects/on_demand/app/hooks';
import KaspiBtn from '@/projects/on_demand/components/KaspBtn';
import { tourSelector } from '@/projects/on_demand/store/slices/tourSlice';
import styles from './styles.module.scss';

type InfoProps = {
  onBtnClick: () => void;
};

export const Info: React.FC<InfoProps> = ({ onBtnClick }) => {
  const { tour, pickupRBS, isSendingData } = useAppSelector(tourSelector);
  if (!tour || !pickupRBS) return null;

  const photo_url = tour.driver.photo_url;
  const name = tour.driver.name;
  const car_name = tour.driver.car_name;
  const car_number_plate = tour.driver.car_number_plate;
  const price = tour.price;
  const time = pickupRBS.arrival_time;

  return (
    <div className={styles.main}>
      <div className={styles.driver}>
        <div className={styles.driverPhoto}>
          {photo_url ? (
            <img src={photo_url} alt="driver" className={styles.driverPhotoImg} />
          ) : (
            <img src={logo} alt="driver" className={styles.driverPhotoImg} />
          )}
        </div>
        <div className={styles.driverText}>
          <div className={styles.driverTextName}>{name}</div>
          <div className={styles.driverTextCar}>{car_name}</div>
          <div className={styles.driverTextNumberPlate}>{car_number_plate}</div>
        </div>
        <div className={styles.price}>
          <div className={styles.priceTitle}>Цена</div>
          <div className={styles.priceValue}>{price} тг</div>
        </div>
        <div className={styles.time}>
          Время прибытия на остановку: <b>~{time}</b>
        </div>
      </div>
      <KaspiBtn isFixedBottom onClick={onBtnClick} disabled={isSendingData} />
    </div>
  );
};
