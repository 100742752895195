import L from 'leaflet';
import { BusStopType } from './@types/tour';

export const getNearestRBS = (latLng: L.LatLng, busStops: BusStopType[]): BusStopType => {
  let nearestStop = busStops[0];
  let minDistance = latLng.distanceTo(L.latLng(nearestStop.location));

  busStops.forEach((bs) => {
    const stopLatLng = L.latLng(bs.location);
    const distance = latLng.distanceTo(stopLatLng);
    if (distance < minDistance) {
      minDistance = distance;
      nearestStop = bs;
    }
  });

  return nearestStop;
};
